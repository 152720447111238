import { Box, Flex } from '@rebass/grid'
import PropTypes from 'prop-types'
import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { Text } from 'rebass'
import CardAgendaItem from 'components/CardAgendaItem'
import CardEditorial from 'components/CardEditorial'
import CardTextual from 'components/CardTextual'
import CardMoney from 'components/CardMoney'
import TemplateCommon from 'components/Templates/TemplateCommon'

class BlockCards extends React.PureComponent {

    static propTypes = {
        columns: PropTypes.number,
        cards: PropTypes.array.isRequired,
        router: PropTypes.object
    }

    render() {
        const { columns = 1, cards, router, locale, fullWidth, title } = this.props;
        let width, gutterX, gutterY, size;

        switch (columns) {
            default:
            case 1:
                width = '100%'
                gutterX = 0
                gutterY = 3
                size = 'L'
                break
            case 2:
                width = [1, 1 / 2, 1 / 2]
                gutterX = 3
                gutterY = 3
                size = 'M'
                break
            case 3:
                width = [1, 1 / 3, 1 / 3]
                gutterX = 3
                gutterY = 3
                size = 'S'
                break
        }
        if (fullWidth) gutterX = 0

        return (
            <Box my={6}>
                <TemplateCommon fullWidth={fullWidth}>
                {title ? (
                    <Box mt={4} mb={2}>
                        <Text fontSize={5} fontWeight="bold">{title}</Text>
                    </Box>
                ) : null}
                <Flex flexWrap='wrap' mx={-gutterX}>

                    {cards.map(card => {
                        let CardComponent
                        const common = {
                            author: card.authorCollection && card.authorCollection.items.length ?
                                card.authorCollection.items[0] : null,
                            body: card.cardBody,
                            category: card.category,
                            externalLink: card.externalLink,
                            internalLink: card.internalLink,
                            key: `card-${locale}-${card.sys.id}`,
                            publicationDate: card.publicationDate,
                            router: router,
                            size: size,
                            tag: card.theme ? { title: card.theme.theme, slug: card.theme.slug, type: "Theme" } : null,
                            teaser: card.teaser,
                            title: card.title,
                            type: card.__typename,
                        }

                        // Links are based customized by the editor.
                        if (card.__typename === "Link") {

                            if (card.style === "Textual") {

                                CardComponent = <CardTextual
                                    {...common}
                                    color={card.color}
                                    cta={card.cta}
                                    illustrativeLetters={card.styleIllustrativeLetters}
                                />

                            } else if (card.style === "Money") {

                                CardComponent = <CardMoney
                                    {...common}
                                    cta={card.cta}
                                    image={card.image ? card.image.url : null} />

                            } else if (card.style === "Editorial") {

                                CardComponent = <CardEditorial
                                    {...common}
                                    tag="This is my tag"
                                    image={card.image ? card.image.url : null} />

                            } else if (card.style === "Agenda item") {

                                CardComponent = <CardEditorial
                                    {...common}
                                    tag="Some tag"
                                    image={card.image ? card.image.url : null}
                                    teaser={card.body} />

                            } else {

                                CardComponent = <strong>unknown card type: {card.style}</strong>

                            }

                        } else {

                            // If the editor points directly to content, rather than to a Link, we 
                            // determine the card style automatically. 
                            switch (card.__typename) {

                                case "Event":
                                case "Exhibition":
                                    CardComponent = (
                                        <CardAgendaItem
                                            {...common}
                                            slug={card.slug}
                                            startingTime={card.startingTime}
                                            endingTime={card.endingTime}
                                            image={card.featuredImage ? card.featuredImage.url : null}
                                            imageAttribution={card.featuredImage ? card.featuredImage.description : null}
                                        />
                                    )
                                    break
                                
                                default:
                                    CardComponent = (
                                        <CardEditorial
                                            {...common}
                                            slug={card.slug}
                                            teaser={card.teaser}
                                            image={card.featuredImage ? card.featuredImage.url : null}
                                            imageAttribution={card.featuredImage ? card.featuredImage.description : null}
                                        />
                                    )
                                    break
                            }
                            

                        }

                        return (
                            <Box width={width} px={gutterX} py={gutterY} key={`card-${locale}-${card.sys.id}`}>
                                <ContainerDimensions>
                                    {CardComponent}
                                </ContainerDimensions>
                            </Box>
                        )

                    })}
                </Flex>
            </TemplateCommon>
            </Box>
        )
    }

}

export default BlockCards