import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import TextTruncate from 'react-text-truncate';
import { Box, Text } from 'rebass';
import styled from 'styled-components';
import { Tag } from 'components/Atoms';
import Link, { LinkWithArrow } from 'components/Link';
import withLocale from 'hoc/withLocale';
import { getContentfulImage, responsiveSize, sameDay } from 'lib/utils';

const messages = defineMessages({
    until: {
        id: 'UI.event.until',
        defaultMessage: 'until'
    },
})

const displayDate = ({ startingTime, endingTime }) => {

    const startDate = new Date(startingTime)
    const endDate = new Date(endingTime)

    if (sameDay(startDate, endDate)) {
        // Single day events
        return (
            <Text fontSize={3} color="white">
                <FormattedDate value={startDate} month="long" day="numeric" />&nbsp;
                <FormattedTime value={startDate} />
                <InlineText mx={1} fontSize={2}>—</InlineText>
                <FormattedTime value={endDate} />
            </Text>

        )
    } else {
        // Exhibitions
        return (
            <Text fontSize={4} color="white">
                <FormattedDate value={startDate} month="long" day="numeric" />&nbsp;
                <InlineText fontSize={3}><FormattedMessage {...messages.until} /></InlineText>&nbsp;
                <FormattedDate value={endDate} month="long" day="numeric" />
            </Text>
        )
    }

}

class CardAgendaItem extends React.PureComponent {

    render() {
        const {
            // endingTime,
            // startingTime,
            image,
            router,
            slug,
            tag,
            teaser,
            title,
            type
        } = this.props;
        const imageOptions = {
            w: responsiveSize(this.props.width),
            h: 427
        }
        const showReadMoreLink = false, showTeaser = false
        const linkData = { slug, type, router }
        return (
            <CardContainer background={image} imageOptions={imageOptions}>
                <ReadabilityGradient />
                <PositionCardText>

                    {tag ? <Tag type={tag.type} mr={3} mb={3} slug={tag.slug} router={router}>{tag.title}</Tag> : null}

                    <Link {...linkData} className="card-editorial-title">{title}</Link>

                    {displayDate(this.props)}

                    {showTeaser ? (
                        <Box my={4}>
                            <Text fontSize={3} color="white" lineHeight={"125%"}>
                                <TextTruncate line={3} text={teaser} />
                            </Text>
                        </Box>
                    ) : null}

                    {showReadMoreLink ? (
                        <Box mt={4}>
                            <LinkWithArrow {...linkData} color="white">
                                <FormattedMessage {...messages.readmore} />
                            </LinkWithArrow>
                        </Box>
                    ) : null}

                </PositionCardText>
            </CardContainer>
        )
    }

}

const InlineText = styled(Text)`
    display: inline-block;
`

const PositionCardText = styled.div`
    position: absolute;
    bottom: 60px;
    left: 0;
    margin: ${props => props.theme.space[4]}px;
    margin-bottom: 0;
    margin-left: 80px;
    max-width: 600px;
`

const CardContainer = styled.div`
    min-height: 427px;
    height: 427px;
    background-image: url(${props => getContentfulImage(props.background, props.imageOptions)});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .card-readmore {
        color: white;
        text-decoration: none;
        border-bottom: solid 1px white;
        display: inline-block;
        padding-bottom: 3px;
        line-height: 100%;
        svg {
            transform: translateX(-3px);
        }
        &:hover {
            opacity: 0.95;
            svg {
                transform: translateX(2px);
                transition: transform 250ms cubic-bezier(0, .52, 0, 1);
            }
        }
    }

    .card-editorial-title {
        color: white;
        text-decoration: none;
        font-weight: bold;
        font-size: ${props => props.theme.fontSizes[6]}px;
        line-height: 120%;
        margin-bottom: ${props => props.theme.space[3]}px;
        display: block;
    }

`;

const ReadabilityGradient = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.51) 35.91%, rgba(0, 0, 0, 0.263793) 83.98%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-180deg);
`

CardAgendaItem.propTypes = {
    image: PropTypes.string.isRequired,
    locale: PropTypes.string,
    router: PropTypes.object,
    teaser: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default withLocale(CardAgendaItem)