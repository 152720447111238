import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, Text } from 'rebass';
import styled from 'styled-components';
import { Tag } from 'components/Atoms/index';
import Link, { LinkButton } from 'components/Link';
import withLocale from 'hoc/withLocale';
import { getContentfulImage, responsiveSize, sameDay } from 'lib/utils';

const messages = defineMessages({
    readmore: {
        id: 'UI.readmore',
        defaultMessage: 'Read more'
    },
})
class CardMoney extends React.PureComponent {

    render() {
        const {
            body,
            internalLink,
            externalLink,
            cta,
            image,
            router,
            slug,
            tag,
            title,
            type,
        } = this.props;
        const imageOptions = {
            w: responsiveSize(this.props.width),
            h: 427
        }
        const linkData = { slug, type, router, internalLink, externalLink }
        return (
            <CardContainer background={image} imageOptions={imageOptions}>
                <ReadabilityGradient />
                <RestrainText mx={4} my={5}>

                    <Link {...linkData} className="card-editorial-title">{title}</Link>

                    <Box my={4}>
                        <Text fontSize={3} color="white" lineHeight={"125%"} dangerouslySetInnerHTML={{__html: body}} />
                    </Box>

                    <Box mt={4}>
                        <LinkButton {...linkData}>
                            {cta ? cta : <FormattedMessage {...messages.readmore} />}
                        </LinkButton>
                    </Box>

                </RestrainText>
            </CardContainer>
        )
    }

}


const RestrainText = styled(Box)`
    max-width: 600px;
    text-align: center;
    position: relative;
`

const CardContainer = styled(Box)`
    
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 427px;
    height: 427px;
    background-color: black;
    background-image: ${props => props.background ? 'url(' + getContentfulImage(props.background, props.imageOptions) + ')' : 'none'};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .card-editorial-title {
        color: white;
        text-decoration: none;
        font-weight: bold;
        font-size: ${props => props.theme.fontSizes[6]}px;
        line-height: 120%;
        margin-bottom: ${props => props.theme.space[3]}px;
        display: block;
    }

`;

const ReadabilityGradient = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.51) 35.91%, rgba(0, 0, 0, 0.263793) 83.98%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-180deg);
`

CardMoney.propTypes = {
    image: PropTypes.string,
    locale: PropTypes.string,
    router: PropTypes.object,
    teaser: PropTypes.string,
    title: PropTypes.string.isRequired,
}

export default withLocale(CardMoney)