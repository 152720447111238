import React from 'react'
import styled from 'styled-components'

const IllustrativeLetters = ({ color, illustrativeLetters }) => {
    // Field is markdown, but we only want to register carriage returns.
    const limitedMarkdown = illustrativeLetters.replace(/(\r\n|\n|\r)/g, "<br />");
    return (
        <React.Fragment>
            <StyledTextTop color={color} dangerouslySetInnerHTML={{ __html: limitedMarkdown }} />
            <StyledTextBottom color={color} dangerouslySetInnerHTML={{ __html: limitedMarkdown }} />
        </React.Fragment>
    )
}

const StyledText = styled.div`
    color: ${props => props.theme.cards[props.color].highlight};
    position: absolute;
    right: -20px;
    font-size: 48px;
    line-height: 90%;
    white-space: nowrap;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 1;
`

const StyledTextTop = styled(StyledText)`
    top: -20px;
`

const StyledTextBottom = styled(StyledText)`
    bottom: -20px;
`

export default IllustrativeLetters