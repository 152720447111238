import React from 'react'
import PropTypes from 'prop-types'
import { Box as RebassBox } from 'rebass'
import { maxWidth, minWidth } from 'styled-system'
import styled from 'styled-components'
import { IconEdit } from 'components/Atoms'
import getConfig from 'next/config';

const getEditLink = (edit) => {
    const { publicRuntimeConfig } = getConfig();
    const { REACT_APP_SPACE_ID } = publicRuntimeConfig;
    return (
        <EditButton>
            <a href={`https://app.contentful.com/spaces/${REACT_APP_SPACE_ID}/entries/${edit}`} target="editor">
                <IconEdit width={14} />
            </a>
        </EditButton>
    )
}

const TemplateCommon = ({ children, fullWidth, edit, ...rest }) => {
    if (fullWidth) {
        return (
            <Container {...rest}>
                {edit ? getEditLink(edit) : null}
                {children}
            </Container>
        )
    }
    return (
        <Container mx={[4, 4, 'auto']} maxWidth={[null, null, 1180, 1580]} {...rest}>
            {edit ? getEditLink(edit) : null}
            {children}
        </Container>
    )
}

export const Box = styled(RebassBox)(maxWidth, minWidth)

//color: ${props => props.theme.colors.accent} !important;
const Container = styled(Box)`
    a:hover {
        opacity: 0.8;
    }
`
const EditButton = styled(Box)`
    position: fixed;
    bottom: 10px;
    right: 10px;    
    background-color: black;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    text-align: center;
    z-index: 1000;
    svg {
        shape-rendering: crispEdges;
        transform: translateY(5px);
    }
`

TemplateCommon.propTypes = {
    children: PropTypes.node.isRequired,
}

export default TemplateCommon;