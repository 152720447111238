import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import BlockCards from 'components/BlockCards';
import testData from 'components/BlockCards/test.data';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "ui-rows"
    }}>{`UI: Rows`}</h1>
    <p>{`Rows are directly tied to layout. They are used by editors to insert specific molecules into a page, either within the text flow of a body element, or into compositional fields that act as page builders. Rows always stack on top of one another, meaning you would never have two rows next to each other. `}</p>
    <h2 {...{
      "id": "one-column-cards"
    }}>{`One column cards`}</h2>
    <Playground __position={0} __code={'<BlockCards columns={1} cards={testData.cardsCollection.items} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      BlockCards,
      testData
    }} mdxType="Playground">
    <BlockCards columns={1} cards={testData.cardsCollection.items} mdxType="BlockCards" />
    </Playground>
    <Playground __position={1} __code={'<BlockCards columns={2} cards={testData.cardsCollection.items} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      BlockCards,
      testData
    }} mdxType="Playground">
    <BlockCards columns={2} cards={testData.cardsCollection.items} mdxType="BlockCards" />
    </Playground>
    <Playground __position={2} __code={'<BlockCards columns={3} cards={testData.cardsCollection.items} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      BlockCards,
      testData
    }} mdxType="Playground">
    <BlockCards columns={3} cards={testData.cardsCollection.items} mdxType="BlockCards" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      