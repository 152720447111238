import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Link, { LinkWithArrow } from 'components/Link'
import { Box } from 'rebass'
import IllustrativeLetters from 'components/IllustrativeLetters'
import Markdown from 'components/Markdown'
import Theme from 'components/Theme/theme'

class CardTextual extends React.PureComponent {

    render() {
        const { title, cta, body, color, illustrativeLetters, router, internalLink, externalLink } = this.props;
        
        return (
            <CardContainer color={color}>
                <Box m={4}>
                    {illustrativeLetters ? (
                        <IllustrativeLetters color={color} illustrativeLetters={illustrativeLetters} />
                    ) : null}
                    <RestrainText>
                        <Link internalLink={internalLink} externalLink={externalLink} router={router} className="card-editorial-title">{title}</Link>
                        {body ? (
                            <Teaser color={color}>
                                <Markdown>{body}</Markdown>
                            </Teaser> 
                        ): null}
                        {internalLink || externalLink ? (
                            <LinkWithArrow
                                color={Theme.cards[color].link}
                                internalLink={internalLink}
                                externalLink={externalLink}>{cta}</LinkWithArrow>
                        ) : null}
                    </RestrainText>
                </Box>
            </CardContainer>
           )
    }

}

const RestrainText = styled.div`
    max-width: 450px;
`

const CardContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 427px;
    height: 427px;
    background-color: ${props => props.theme.cards[props.color].background};
    position: relative;
    overflow: hidden;

    .card-editorial-title {
        color: ${props => props.theme.cards[props.color].text};
        text-decoration: none;
        font-weight: bold;
        font-size: ${props => props.theme.fontSizes[6]}px;
        line-height: 100%;
        margin-bottom: ${props => props.theme.space[3]}px;
        display: block;
    }

`;

const Teaser = styled(Box)`
    color: ${props => props.theme.cards[props.color].text};
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 150%;
`

CardTextual.propTypes = {
    router: PropTypes.object,
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string,
}

export default CardTextual